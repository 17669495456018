<template>
    <div class="template-base">
        <MessageBoxV3 v-show="messageBox_show" ref="refMessageBox" />
        <Header Title="Station Dashboard" class="div-header" />
        <div class="menu-bar dashboard-menu-bar" v-show="showFilters">            
            <Button class="x-smaller-button" :buttonModel="btnExit" />
            <div style="display:flex;align-items:flex-start; flex-direction:column;margin: 0px 10px;" v-show="!fetchingData">
                <div>Sites</div>
                <div style="display: flex; flex-direction: row ;flex-wrap:wrap">
                    <select class="" v-model="currentTenant" @change="tenantSelect" style="width:300px;height:34px">
                        <option v-for="tenant in tenants" :key="tenant.tenantId" :value="tenant.tenantId">{{ tenant.tenantName }}</option>
                    </select>
                
                    <div>
                        <input ref="refSearchItem" placeholder="search" type="text" class="dashboard-site-search" required list="siteItems" @change="setTenant" />
                    </div>
                    <datalist id="siteItems">
                        <option v-for="tenant in tenants" :key="tenant.tenantId" :value="tenant.tenantName"></option>
                    </datalist>
                </div>
            </div>
            <div style="display:flex;align-items:flex-start; flex-direction:column;margin: 0px 10px" v-show="!fetchingData">
                <div>Timezones</div>
                <div style="display: flex; flex-direction: row ;">
                    <select class="" v-model="currentTimezone" style="width:300px;height:34px">
                        <option v-for="timezone in timezones" :key="timezone" :value="timezone">{{ timezone }}</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="option-bar" v-show="showFilters">
            <div style="display:flex;align-items:center; flex-direction:row;margin: 0px 10px" v-show="!fetchingData">
                <input type="checkbox" id="checkReadable" v-model="showReadable" />
                <label for="checkReadable">Readable</label>
            </div>
            <div style="display:flex;align-items:center; flex-direction:row;margin: 0px 10px" v-show="!fetchingData">
                <input type="checkbox" id="checkVerions" v-model="showDifferentVersions" />
                <label for="checkVerions">Different Versions</label>
            </div>
            <div style="display:flex;align-items:center; flex-direction:row;margin: 0px 10px" v-show="!fetchingData">
                <input type="checkbox" id="checkRunning" v-model="showRunning" />
                <label for="checkRunning">Running</label>
            </div>
            <div style="display:flex;align-items:center; flex-direction:row;margin: 0px 10px" v-show="!fetchingData">
                <input type="checkbox" id="checkvNext" v-model="showVNext" />
                <label for="checkvNext">vNext</label>
            </div>
            <div style="display:flex;align-items:center; flex-direction:row;margin: 0px 10px" v-show="!fetchingData">
                <input type="checkbox" id="checkvNextNot" v-model="showNotVNext" />
                <label for="checkvNextNot">Not vNext</label>
            </div>
            <div style="display:flex;align-items:center; flex-direction:row;margin: 0px 10px" v-show="!fetchingData">
                <input type="input" v-model="filterVersion" placeholder="Version Filter" />
            </div>

            <div style="display:flex;align-items:space-around; flex-direction:row;margin: 0px 10px;width:300px" v-show="!fetchingData">
                <button class="button-link" @click="rebootByVersion" style="width:50%;color:cornflowerblue">Reboot by Version</button>                
            </div>
        </div>
        <div class="option-bar">
            <div style="display:flex;align-items:flex-end; flex-direction:column;margin: 0px 10px;width:100%" v-show="!fetchingData">                
                <button class="button-link" @click="showFilters = !showFilters" style="color:cornflowerblue"> {{showFilters ? 'Hide Filters' : 'Show Filters' }}</button>
            </div>
        </div>

        <div class="select-button-container-tenant div-body">
            <div style="display:flex; flex-direction: columns;justify-content:space-between;background-color: green;">
                <div style="color:white;font-size: 10pt;">Stations: {{ stations.length }}</div>
                <div style="display:flex; flex-direction: columns;justify-content:flex-end;background-color: #2196e2;"></div>
            </div>
            <div style="background: white; overflow-y: auto;height: 90%;">
                <table class="report-div-table" cellspacing="0">
                    <tr class="report-div-table-row" style="background:green;top:0;position: sticky;">
                        <td class="report-div-table-col" style="width:15%;color:white">Site</td>
                        <td class="report-div-table-col" style="width:5%;color:white">Timezone</td>
                        <td class="report-div-table-col" style="width:10%;color:white">Station</td>
                        <td class="report-div-table-col" style="width:10%;color:white">Platform</td>
                        <td class="report-div-table-col" style="width:10%;color:white">Station Type</td>
                        <td class="report-div-table-col" style="width:5%;color:white">Version</td>
                        <td class="report-div-table-col" style="width:10%;color:white">Last Start</td>
                        <td class="report-div-table-col" style="width:10%;color:white">Last Ping</td>
                        <td class="report-div-table-col" style="width:10%;color:white">Last Action</td>
                        <td class="report-div-table-col" style="width:5%;color:white;text-align: right;">Time Diff (ms)</td>
                        <td class="report-div-table-col" style="width:5%;color:white">Status</td>
                        <td class="report-div-table-col" style="width:30%;color:white">Action Info</td>
                        <td class="report-div-table-col" style="width:20%;color:white">Station Commands</td>
                    </tr>
                    <tr v-for="row in stations" :key="row.heading" class="report-div-table-row">
                        <td class="report-div-table-col" style="">
                            <div>
                                <div>
                                    <div :style="tenants.filter((x) => x.tenantId == row.tenantId)[0].isVNext ? 'color:blue' : ''">
                                        {{ row.tenantName }}
                                    </div>
                                    <!-- <div style="font-size: 9pt;">
                                        {{ tenants.filter(x=> x.tenantId == row.tenantId)[0].timezone }}
                                    </div> -->
                                    <div v-if="row.stationType == 'Dispenser' || row.stationType == 'TBox'">
                                        <img src="/images/icons/open.png" class="button-link image-pad" @click="onSetupQrCode(row.tenantName, row.stationName, row.tenantId, row.stationId)" style="width:20px" title="Open Station QR Code Page" />
                                        <img src="/images/icons/copy.png" class="button-link image-pad" @click="getStationInfo(row.tenantId, row.stationId, row.stationType)" style="width:20px" title="Copy Station Settings" />
                                        <img src="/images/icons/download.png" class="button-link image-pad-link" @click="getStationGlobalId(row.tenantId, row.stationId)" style="width:20px" title="Download .select_global_identifierv2" />
                                        <img
                                            src="/images/icons/info.png"
                                            class="button-link image-pad-link"
                                            @click="displayReaderInfo(row.readerInfoDisplay)"
                                            style="width:20px"
                                            title="Display Card Reader Information"
                                            v-if="row.readerInfoDisplay.length > 0"
                                        />
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td class="report-div-table-col" style="">{{ tenants.filter((x) => x.tenantId == row.tenantId)[0].timezone }}</td>
                        <td class="report-div-table-col" style="">{{ row.stationName }}</td>
                        <td class="report-div-table-col" style="">{{ row.platformDisplay }}</td>
                        <td class="report-div-table-col" style="">{{ row.stationType }}</td>
                        <td class="report-div-table-col" style="">{{ row.version }}</td>

                        <td v-if="!showReadable" class="report-div-table-col" style="">{{ row.lastStartDisplay }}</td>
                        <td v-if="showReadable" class="report-div-table-col" style="">{{ Helper.ToDateReadableShort(row.lastStart) }}</td>

                        <td v-if="!showReadable" class="report-div-table-col" style="">{{ row.timestampDisplay }}</td>
                        <td v-if="showReadable" class="report-div-table-col" style="">{{ Helper.ToDateReadableShort(row.timestamp) }}</td>

                        <td v-if="!showReadable" class="report-div-table-col" style="">{{ row.lastTransactionDisplay }}</td>
                        <td v-if="showReadable" class="report-div-table-col" style="">{{ Helper.ToDateReadableShort(row.lastTransaction) }}</td>

                        <td class="report-div-table-col" style="text-align: right;">{{ row.stationTimeDiff }}</td>

                        <td v-if="row.statusDisplay" class="report-div-table-col" style="font-size:30pt;color:green">&#8679;</td>
                        <td v-if="!row.statusDisplay" class="report-div-table-col" style="font-size:30pt;color:red">&#8681;</td>
                        <td class="report-div-table-col" style="">{{ row.actionInfo }}</td>
                        <td class="report-div-table-col" style="">
                            <div style="display:flex;" v-if="row.stationType == 'Dispenser' || row.stationType == 'TBox'">
                                <img src="/images/icons/close.png" class="button-link image-pad" @click="close(row.stationId)" style="width:30px" title="Close Station" v-if="row.stationType == 'Dispenser'" />
                                <img src="/images/icons/reboot.png" class="button-link image-pad" @click="reboot(row.stationId)" style="width:30px" title="Reboot Station" />
                                <img src="/images/icons/install.png" class="button-link image-pad" @click="reinstall(row.stationId)" style="width:30px" title="Reinstall Station" v-if="row.stationType == 'Dispenser'" />
                                <img src="/images/icons/reset.png" class="button-link image-pad" @click="reset(row.stationId)" style="width:30px" title="Reset Station" />
                                <button class="button-link" style="" @click="bezel8Upgrade(row.stationId)" v-if="row.stationType == 'Dispenser'"><span>Update Card Reader</span></button>
                                <button class="button-link" style="" @click="updateLogs(row.stationId)" v-if="row.stationType == 'Dispenser'">
                                    <span>Upload<br />Logs</span>
                                </button>
                                <button class="button-link" style="" @click="forceReboot(row.stationId)" v-if="row.stationType == 'Dispenser'" title="Force Station Reboot"><span>Force Reboot</span></button>
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import Header from "@/views/components/Shared/Header";
import Button from "@/views/components/Shared/Button";
import ButtonModel from "@/common/models/ButtonModel";
import { ref } from "@vue/reactivity";
import { getCurrentInstance, onMounted, onUnmounted } from "@vue/runtime-core";
import apiTenant from "@/api/apiTenant";
import apiStation from "@/api/apiStation";
import StationCommandModel from "@/common/models/StationCommandModel";
import Helper from "@/common/helper";
import axios from "axios";
import PortalUserModel from "@/common/models/PortalUserModel";
import MessageBoxV3 from "@/views/components/Shared/MessageBoxV3";

export default {
    components: { Header, Button, MessageBoxV3 },
    setup() {
        const emitter = getCurrentInstance().appContext.app.config.globalProperties.emitter;

        const fetchingData = ref(true);

        const tenants = ref([]);
        const timezones = ref([]);
        const searchNameText = ref("");
        const tenantModel = ref();
        const inputSearch = ref(null);
        const currentTenant = ref(null);
        const currentTimezone = ref(null);
        const showReadable = ref(false);

        const stations = ref([]);

        const statusInterval = ref();

        const showDifferentVersions = ref(false);
        const showRunning = ref(false);
        const showVNext = ref(false);
        const showNotVNext = ref(false);
        const refSearchItem = ref(null);
        const filterVersion = ref("");
        const refMessageBox = ref(null);
        const messageBox_show = ref(false);
        const showFilters = ref(true);

        const btnExit = ButtonModel.CreateRoute("", "Home");
        btnExit.iconPath = "/images/icons/exit.png";
        btnExit.title = "Back to Main Page";

        onMounted(async () => {
            await getTenants();
            await getStatusStatus();
            statusInterval.value = window.setInterval(getStatusStatus, 5000);
        });

        onUnmounted(() => {
            window.clearInterval(statusInterval.value);
        });

        const getTenants = async () => {
            try {
                emitter.emit("server-call-start", "Loading...");
                fetchingData.value = true;
                tenants.value = await apiTenant.getTenantTimezoneList();
                timezones.value = Array.from(new Set(tenants.value.map((t) => t.timezone))).sort((a, b) => (a > b ? 1 : b > a ? -1 : 0));
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error loading data", err]);
            }
            emitter.emit("server-call-stop");
            fetchingData.value = false;
        };

        var portalUser = PortalUserModel.fromToken();

        const getStatusStatus = async () => {
            try {
                var list = null;
                if (currentTenant.value == null) list = await apiStation.getStationStatus("");
                else list = await apiStation.getStationStatus(currentTenant.value);

                if (showDifferentVersions.value) list = list.filter((x) => x.version != portalUser.version);
                if (showRunning.value) list = list.filter((x) => x.statusDisplay);
                if (filterVersion.value.length > 0) list = list.filter((x) => x.version == filterVersion.value);
                if (currentTimezone.value != null) {
                    // Get tenants with this timezone
                    var tzTenants = tenants.value.filter((x) => x.timezone == currentTimezone.value).map((t) => t.tenantId);
                    list = list.filter((x) => tzTenants.includes(x.tenantId));
                }
                if (showVNext.value) {
                    var vnTenants = tenants.value.filter((x) => x.isVNext).map((t) => t.tenantId);
                    list = list.filter((x) => vnTenants.includes(x.tenantId));
                }
                if (showNotVNext.value) {
                    var nvnTenants = tenants.value.filter((x) => !x.isVNext).map((t) => t.tenantId);
                    list = list.filter((x) => nvnTenants.includes(x.tenantId));
                }

                stations.value = list;
            } catch (err) {
                console.log("ERROR:", err);
            }
        };

        const tenantSelect = async () => {
            refSearchItem.value.value = "";
            await getStatusStatus();
        };

        const reboot = async (stationId) => {
            var command = new StationCommandModel();
            command.stationId = stationId;
            command.reboot = true;
            await sendCommand(command);
        };

        const forceReboot = async (stationId) => {
            let result = confirm("Are you sure you want to force station to reboot?");
            if (result) {
                var command = new StationCommandModel();
                command.stationId = stationId;
                command.forceReboot = true;
                await sendCommand(command);
            }
        };

        const reset = async (stationId) => {
            let result = confirm("Are you sure you want to reset station configuration?");
            if (result) {
                var command = new StationCommandModel();
                command.stationId = stationId;
                command.reset = true;
                await sendCommand(command);
            }
        };

        const reinstall = async (stationId) => {
            var command = new StationCommandModel();
            command.stationId = stationId;
            command.reinstall = true;
            await sendCommand(command);
        };

        const close = async (stationId) => {
            let result = confirm("Are you sure you want to close station?");
            if (result) {
                var command = new StationCommandModel();
                command.stationId = stationId;
                command.close = true;
                await sendCommand(command);
            }
        };

        const bezel8Upgrade = async (stationId) => {
            let result = confirm("Are you sure you want to update the card reader?");
            if (result) {
            var command = new StationCommandModel();
            command.stationId = stationId;
            command.bezel8Upgrade = true;
            await sendCommand(command);
            }
        };

        const updateLogs = async (stationId) => {
            var command = new StationCommandModel();
            command.stationId = stationId;
            command.uploadLogs = true;
            var dates = prompt("Enter dates separated by comma (yyyy-mm-dd)", new Date().yyyy_mm_dd());
            if (dates !== null && dates.trim().length > 0) {
                command.uploadLogsDates = dates;
                await sendCommand(command);
            }
        };

        const sendCommand = async (command) => {
            try {
                emitter.emit("server-call-start", "Saving...");
                await apiStation.saveCommands(command);
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error saving data", err]);
            }
            emitter.emit("server-call-stop");
        };

        const getStationInfo = async (tenantId, stationId, stationType) => {
            try {
                emitter.emit("server-call-start", "Getting data...");
                var info = "";

                if (stationType == "Dispenser" || stationType == "Turnstile" || stationType == "TBox") {
                    info = await apiStation.getInfo(tenantId, stationId);
                }

                var dummy = document.createElement("textarea");
                document.body.appendChild(dummy);
                dummy.value = info;
                dummy.select();
                document.execCommand("copy");
                document.body.removeChild(dummy);
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error getting data", err]);
            }
            emitter.emit("server-call-stop");
        };

        const displayReaderInfo = async (readerInfo) => {
            var jsonObj = JSON.parse(readerInfo);
            var jsonPretty = JSON.stringify(jsonObj, undefined, 4);

            refMessageBox.value.showDialog(
                jsonPretty,
                "Card Reader Information",
                null,
                () => {
                    return (messageBox_show.value = false);
                },
                "",
                "Close"
            );

            refMessageBox.value.setHeight("300px");
            messageBox_show.value = true;

            //alert(jsonPretty)
        };

        const getStationGlobalId = (tenantId, stationId) => {
            try {
                var fileText = `{ "tenantId" : "${tenantId}", "stationId" : "${stationId}", "endpoint": "${axios.defaults.baseURL.replace(/\/$/, "")}" }`;

                var element = document.createElement("a");
                element.setAttribute("href", "data:select_global_identifierv2/plain;charset=utf-8," + encodeURIComponent(fileText));
                element.setAttribute("download", ".select_global_identifierv2");

                element.style.display = "none";
                document.body.appendChild(element);

                element.click();

                document.body.removeChild(element);
            } catch (err) {
                console.log("ERROR:", err);
            }
        };

        const onSetupQrCode = (tenantName, stationName, tenantId, stationId) => {
            Helper.stationSetupCode(escape(tenantName), escape(stationName), escape(tenantId), escape(stationId), escape(axios.defaults.baseURL), window);
        };

        const onSetupQrCodeCopied = (tenantName, stationName, tenantId, stationId) => {
            var data = Helper.stationSetupCodeUrl(escape(tenantName), escape(stationName), escape(tenantId), escape(stationId), escape(axios.defaults.baseURL), window);

            var dummy = document.createElement("textarea");
            document.body.appendChild(dummy);
            dummy.value = data;
            dummy.select();
            document.execCommand("copy");
            document.body.removeChild(dummy);
        };

        const rebootByVersion = async () => {
            var version = prompt("Enter version");
            if (version != null) {
                await apiStation.rebootAll(version);
            }
        };

        const setTenant = async () => {
            currentTenant.value = tenants.value.filter((x) => x.tenantName == refSearchItem.value.value)[0].tenantId;
            await tenantSelect();
        };

        return {
            btnExit,
            tenants,
            searchNameText,
            tenantModel,
            tenantSelect,
            inputSearch,
            fetchingData,
            currentTenant,
            currentTimezone,
            stations,
            reboot,
            forceReboot,
            reset,
            reinstall,
            close,
            bezel8Upgrade,
            updateLogs,
            onSetupQrCodeCopied,
            onSetupQrCode,
            getStationInfo,
            getStationGlobalId,
            showDifferentVersions,
            showRunning,
            showVNext,
            showNotVNext,
            rebootByVersion,
            setTenant,
            refSearchItem,
            filterVersion,
            timezones,
            showReadable,
            Helper,
            displayReaderInfo,
            refMessageBox,
            messageBox_show,
            showFilters
        };
    }
};
</script>

<style scoped>
.image-pad {
    margin-left: 5px;
}

.option-bar {
    background: var(--menu-bar-backgroud);
    color: var(--menu-bar-color);
    display: flex;
    padding: 5px 5px;
    border-bottom: 2px black solid;
    flex-wrap: wrap;
}

/* @media (orientation: landscape) {         */
@media (pointer: fine), (pointer: none) {
    .dashboard-site-search {
        margin-left: 10px;
        width: 300px;
        height: 22px;
    }
}

@media (orientation: portrait) {
    .dashboard-menu-bar {
        flex-wrap: wrap;
        /* visibility: hidden; */
    }

    .dashboard-site-search {
        margin-top: 10px;
        margin-bottom: 10px;
        width: 70vw;
        height: 22px;
    }
}
</style>
