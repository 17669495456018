import ApiBaseModel from "@/common/models/ApiBaseModel";
import { StationTypes, CardReaderTypes, PulseProfileTypes } from "@/common/enums";

export default class StationModel extends ApiBaseModel {
    stationName: string = "";
    stationType: StationTypes = StationTypes.Dispenser;
    useBillAcceptor: boolean = false;
    disablePincodes: boolean = false;
    disableCreditCards: boolean = false;
    disableMemberCards: boolean = false;
    disableMobileApp: boolean = false;
    disableCardReader: boolean = false;
    useQrCodes: boolean = false;
    useDataCap: boolean = false;
    currencyCode: number = 840;
    startUpDelay: number = 10;
    isChannelDispenser: boolean = false;
    channelBallCount: number = 9;    
    siteSettings: string = "sitesetting01";
    bayId: number = 0;
    hideBalls: boolean = false;
    rebootOnFreeze: boolean = false;
    stationHomeImage: string = "";
    dispenserId: string = ""
    enableDispensePush: boolean = false;
    dynamicQR: boolean = false;
    cardReaderType: CardReaderTypes = CardReaderTypes.Unknown;
    pulseLength: number = 50;
    pulseSeparator: number = 250;
    directPincodeScan: boolean = false;    
    pulseProfile: PulseProfileTypes = PulseProfileTypes.RangeServant;    

    static from(json: string): StationModel {
        let model = Object.assign(new StationModel(), json);
        return model;
    }
}
