

import Helper from "@/common/helper.js"

export default class StationCommandModel {    
    stationId: string = "";
    reboot: boolean | null | undefined;
    reset: boolean | null | undefined;
    reinstall: boolean | null | undefined;
    close: boolean | null | undefined;
    bezel8Upgrade: boolean | null | undefined;
    uploadLogs: boolean | null | undefined;
    uploadLogsDates: string = "";
    forceReboot: boolean | null | undefined;

    static from(json: string): StationCommandModel {
        let model = Object.assign(new StationCommandModel(), json);
        return model;
    }
}


