<template>
    <div class="template-base">
        <Header Title="" class="div-header" />
        <div class="div-body" style="height:100%">
            <div class="cashier-top-container">
                <div class="cashier-container">
                    <div style="width:100%">
                        <div style="padding-left:10px" v-if="hotkeyBuckets.length > 0"><h3>Buckets</h3></div>
                        <hr style="width:99%" v-if="hotkeyBuckets.length > 0" />
                        <div v-if="hotkeyBuckets.length > 0" class="hotkey-container">
                            <Button
                                v-for="hotkey in hotkeyBuckets"
                                :key="hotkey.KeyCaption"
                                :buttonModel="hotkey"
                                @on-clicked="procesHotkey"
                                :textColor="textColor"
                                :captionFontSize="captionFontSize"
                                :commandFontSize="commandFontSize"
                                :disabled="processingHotkey"
                                :captionFont="buttonFontFamily"
                            />
                        </div>
                    </div>

                    <div style="width:100%">
                        <div style="padding-left:10px" v-if="hotkeyReloads.length > 0"><h3>Member Reloads</h3></div>
                        <hr style="width:99%" v-if="hotkeyReloads.length > 0" />
                        <div v-if="hotkeyReloads.length > 0" class="hotkey-container">
                            <Button
                                v-for="hotkey in hotkeyReloads"
                                :key="hotkey.KeyCaption"
                                :buttonModel="hotkey"
                                @on-clicked="procesHotkey"
                                :textColor="textColor"
                                :captionFontSize="captionFontSize"
                                :commandFontSize="commandFontSize"
                                :disabled="processingHotkey"
                                :captionFont="buttonFontFamily"
                            />
                        </div>
                    </div>

                    <div style="width:100%">
                        <div style="padding-left:10px" v-if="hotkeyTBox.length > 0"><h3>Tee-Box Time</h3></div>
                        <hr style="width:99%" v-if="hotkeyTBox.length > 0" />
                        <div v-if="hotkeyTBox.length > 0" class="hotkey-container">
                            <Button
                                v-for="hotkey in hotkeyTBox"
                                :key="hotkey.KeyCaption"
                                :buttonModel="hotkey"
                                @on-clicked="procesHotkey"
                                :textColor="textColor"
                                :captionFontSize="captionFontSize"
                                :commandFontSize="commandFontSize"
                                :disabled="processingHotkey"
                                :captionFont="buttonFontFamily"
                            />
                        </div>
                    </div>

                    <div style="width:100%">
                        <div style="padding-left:10px" v-if="hotkeySaleItems.length > 0"><h3>Sale Items</h3></div>
                        <hr style="width:99%" v-if="hotkeySaleItems.length > 0" />
                        <div v-if="hotkeySaleItems.length > 0" class="hotkey-container">
                            <Button
                                v-for="hotkey in hotkeySaleItems"
                                :key="hotkey.KeyCaption"
                                :buttonModel="hotkey"
                                @on-clicked="procesHotkey"
                                :textColor="textColor"
                                :captionFontSize="captionFontSize"
                                :commandFontSize="commandFontSize"
                                :disabled="processingHotkey"
                                :captionFont="buttonFontFamily"
                            />
                        </div>
                    </div>

                    <div style="width:100%">
                        <div style="padding-left:10px" v-if="hotkeyDoors.length > 0"><h3>Doors</h3></div>
                        <hr style="width:99%" v-if="hotkeyDoors.length > 0" />
                        <div v-if="hotkeyDoors.length > 0" class="hotkey-container">
                            <Button
                                v-for="hotkey in hotkeyDoors"
                                :key="hotkey.KeyCaption"
                                :buttonModel="hotkey"
                                @on-clicked="procesHotkey"
                                :textColor="textColor"
                                :captionFontSize="captionFontSize"
                                :commandFontSize="commandFontSize"
                                :disabled="processingHotkey"
                                :captionFont="buttonFontFamily"
                            />
                        </div>
                    </div>

                    <div style="width:100%">
                        <div style="padding-left:10px" v-if="hotkeySystem.length > 0"><h3>System</h3></div>
                        <hr style="width:99%" v-if="hotkeySystem.length > 0" />
                        <div v-if="hotkeySystem.length > 0" class="hotkey-container">
                            <Button
                                v-for="hotkey in hotkeySystem"
                                :key="hotkey.KeyCaption"
                                :buttonModel="hotkey"
                                @on-clicked="procesHotkey"
                                :textColor="textColor"
                                :captionFontSize="captionFontSize"
                                :commandFontSize="commandFontSize"
                                :disabled="processingHotkey"
                                :captionFont="buttonFontFamily"
                            />
                        </div>
                    </div>
                    <div style="width:100%;height:50px">
                        <!-- 
                            Here to make the scroll turn on at the right place
                         -->
                    </div>
                </div>
                <div class="status-container">
                    <div v-for="status in stationStatus" :key="status.stationId" class="status" :title="'Last contact ' + status.timestampDisplay">
                        <div class="station-name">{{ status.stationName }}</div>                        
                        <div v-if="status.canDisable">
                            <ToggleButton2 :text="!status.disabled ? 'On' : 'Off'" v-model="status.enabled" style="margin-left: 5px;width:50px" :title="!status.disabled ? 'Click to disable station' : 'Click to enable station'" :keyId="status.stationId" @toggled="enableStation"></ToggleButton2>
                        </div>
                        <div v-if="status.canDisable" style="margin-right:55px">                            
                        </div>
                        <div v-if="status.statusDisplay" class="station"><div class="station-up"></div></div>
                        <div v-if="!status.statusDisplay" class="station"><div class="station-down"></div></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- prettier-ignore -->
    <CashLog v-show="showCashLog" @on-close="showCashLog = false; enableKeyCapture(); " ref="refCashLog" />
    <!-- prettier-ignore -->
    <TBoxes v-show="showTBoxes" @on-close=" showTBoxes = false; enableKeyCapture();" ref="refTBoxes"/>
    <ReportEndOfShift style="width:100%" ref="refEndOfShift" v-show="showEndOfShift" @on-close="onReportClose" :showCurrentSession="true" />
    <MemberLookup ref="refMemberLookup" v-show="showMemberLookup" @on-close="onMemberClose" />
    <MemberDisplay ref="refMemberDisplay" v-show="showMemberDisplay" @on-close="onMemberClose" />
    <MemberReload ref="refMemberReload" v-show="showMemberReload" @on-close="onMemberClose" />
    <iframe ref="refPrintFrame" src="" frameborder="0" width="0px" height="0px" id="cashier_print_frame"></iframe>
    <input id="cashier_hidden_value" type="hidden" ref="refHiddenValue" value="0" />
    <input id="cashier_disable_print" type="hidden" ref="refDisablePrint" value="0" />
</template>

<script>
import apiHotkey from "@/api/apiHotkey";
import Button from "@/views/components/Shared/Button";
import Header from "@/views/components/Shared/Header";
import { ref } from "vue";
import ButtonModel from "@/common/models/ButtonModel";
import { ButtonTypes, HotKeyTypes } from "@/common/enums";
import apiSettings from "@/api/apiSettings";
import { getCurrentInstance, onBeforeUnmount, onMounted } from "@vue/runtime-core";
import PinTicketModel from "@/common/models/PinTicketModel";
import apiPincode from "@/api/apiPincode";
import apiToken from "@/api/apiToken";
import apiMember from "@/api/apiMember";
import Helper from "@/common/helper";
import CashLog from "@/views/components/Shared/CashLog";
import TBoxes from "@/views/components/Shared/TBoxes";
import apiStation from "@/api/apiStation";
import apiLogging from "@/api/apiLogging";
import apiGiftCard from "@/api/apiGiftCard";
import MessageBoxModel from "@/common/models/MessageBoxModel";
import { onBeforeRouteLeave } from "vue-router";
import router from "@/router";
import ReportEndOfShift from "@/views/components/Reports/ReportEndOfShift.vue";
import PortalUserModel from "@/common/models/PortalUserModel";
import MemberLookup from "@/views/components/Shared/MemberLookup";
import MemberDisplay from "@/views/components/Shared/MemberDisplay";
import MemberReload from "@/views/components/Shared/MemberReload";
import Utilities from "@/common/utilities";
import ToggleButton2 from "@/views/components/Shared/ToggleButton2";

export default {
    components: {
        Button,
        Header,
        CashLog,
        TBoxes,
        ReportEndOfShift,
        MemberLookup,
        MemberDisplay,
        MemberReload,
        ToggleButton2
    },
    setup() {
        const hotkeys = ref([]);
        const emitter = getCurrentInstance().appContext.app.config.globalProperties.emitter;

        const pincodePreferences = ref(null);

        // TODO: Make these configurable
        const textColor = ref("black");
        const captionFontSize = ref("14pt");
        const commandFontSize = ref("12pt");

        const refCashLog = ref(null);
        const refTBoxes = ref(null);
        const showCashLog = ref(false);
        const showTBoxes = ref(false);
        const hotkeyBuckets = ref([]);
        const hotkeyReloads = ref([]);
        const hotkeyTBox = ref([]);
        const hotkeySaleItems = ref([]);
        const hotkeyDoors = ref([]);
        const hotkeySystem = ref([]);

        const stationStatus = ref([]);

        const statusInterval = ref();

        const showEndOfShift = ref(false);
        const refEndOfShift = ref(false);

        const refPrintFrame = ref(null);
        const refHiddenValue = ref(null);
        const refDisablePrint = ref(null);

        const refMemberLookup = ref(null);
        const showMemberLookup = ref(false);

        const refMemberDisplay = ref(null);
        const showMemberDisplay = ref(false);

        const refMemberReload = ref(null);
        const showMemberReload = ref(false);

        const processingHotkey = ref(false);

        var portalUser = PortalUserModel.fromToken();

        const buttonFontFamily = ref(portalUser.cashierOverrideFont);

        let enableTBox = false;
        let enableCashLog = false;

        onMounted(() => {
            getData();
            enableKeyCapture();
            statusInterval.value = window.setInterval(getStatusStatus, 60000);
        });

        onBeforeUnmount(() => {
            emitter.off("keydownHandler", processCmdKey);
            window.clearInterval(statusInterval.value);
        });

        const disableKeyCapture = () => {
            emitter.off("keydownHandler", processCmdKey);
        };

        const enableKeyCapture = () => {
            emitter.off("keydownHandler", processCmdKey);
            emitter.on("keydownHandler", processCmdKey);
        };

        var gotoAfterReport = "";
        var ignoreExit = false;
        onBeforeRouteLeave(async (to) => {
            if (portalUser.isAdminPlus) ignoreExit = true;
            if (ignoreExit) return true;
            gotoAfterReport = to.fullPath;
            await printEndOfShiftReport();
            return false;
        });

        const printEndOfShiftReport = async () => {
            var messageBoxModel = new MessageBoxModel();
            messageBoxModel.caption = "End of Shift Report";
            messageBoxModel.message.push("");
            messageBoxModel.message.push(`View End of Shift Report?`);
            messageBoxModel.message.push("");
            messageBoxModel.btnYes = true;
            messageBoxModel.btnNoText = "No";
            messageBoxModel.defaultYes = true;
            messageBoxModel.callbackId = "cashier-endofshift";
            emitter.emit("show-message-box", messageBoxModel);
        };

        emitter.off("message-box-" + "cashier-endofshift");
        emitter.on("message-box-" + "cashier-endofshift", async () => {
            await refEndOfShift.value.getData(portalUser.session);
            showEndOfShift.value = true;
        });

        emitter.off("message-box-no-" + "cashier-endofshift");
        emitter.on("message-box-no-" + "cashier-endofshift", async () => {
            ignoreExit = true;
            router.replace(gotoAfterReport);
        });

        const onReportClose = () => {
            showEndOfShift.value = false;
            if (refEndOfShift.value.reportTitle != "Cashier Daily Report") ignoreExit = true;
            router.replace(gotoAfterReport);
        };

        var lastKeyinput = "";
        var lastkeyTime = new Date().getTime();
        const processCmdKey = async (event) => {
            console.log("processCmdKey", event);

            var timeDiff = new Date().getTime() - lastkeyTime;
            if (timeDiff > parseInt(portalUser.cardReaderThreshold)) {
                lastKeyinput = "";
            }

            // Check for range card
            if (event.key === "Enter") {
                if (timeDiff < parseInt(portalUser.cardReaderThreshold)) {
                    var inputValue = lastKeyinput;
                    lastKeyinput = "";

                    if (inputValue !== null && inputValue.trim().length > 0) {
                        // Process inputValue
                        await getMemberByCardId(inputValue);
                    }
                }
                return;
            } else {
                if (event.key === "ArrowDown") return;
                if (event.key === "Shift") return;
                lastKeyinput += event.key;
            }
            lastkeyTime = new Date().getTime();

            // Search for hotkey
            var cmd = Helper.generateHotKeyCmd(event);
            if (cmd !== null) {
                for (let hotkey of hotkeys.value) {
                    if (cmd == hotkey.hotkey) {
                        event.preventDefault();
                        await procesHotkey(hotkey);
                        break;
                    }
                }
                return;
            }
        };

        const getStatusStatus = async () => {
            try {
                stationStatus.value = await apiStation.getStationStatusUser();
            } catch (err) {
                console.log("ERROR:", err);
            }
        };

        emitter.on("close-customer-lookup", () => {
            //enableKeyCapture();
        });

        const onMemberClose = async () => {
            showMemberDisplay.value = false;
            showMemberLookup.value = false;
            showMemberReload.value = false;
            processingHotkey.value = false;
            enableKeyCapture();
        };

        const procesHotkey = async (hotkey) => {
            if (hotkey.buttonCaption === "Lookup Pincode") {
                await pinLookup();
            } else if (hotkey.buttonCaption === "Cash Log") {
                if (await refCashLog.value.loadData()) {
                    disableKeyCapture();
                    showCashLog.value = true;
                }
            } else if (hotkey.buttonCaption === "Lookup Member") {
                disableKeyCapture();
                refMemberLookup.value.setMember();
                showMemberLookup.value = true;
            } else if (hotkey.buttonCaption === "Lookup Card") {
                var cardNumber = prompt("Enter Card Number");
                if (cardNumber !== null && cardNumber.trim().length > 0) {
                    await getMemberByCardId(cardNumber);
                }
            } else if (hotkey.buttonCaption === "Sign Out") {
                await apiToken.logout();
                localStorage.clear();
                window.location = window.location.protocol + "//" + window.location.host;
            } else if (hotkey.buttonCaption === "Cashier Daily Report") {
                refEndOfShift.value.reportTitle = "Cashier Daily Report";
                refEndOfShift.value.reportfilename = "CashierDailyReport";
                await refEndOfShift.value.getDataDay(portalUser.username, new Date().yyyy_mm_dd().replaceAll("-", ""));
                showEndOfShift.value = true;
            } else if (hotkey.buttonCaption === "Tee-Boxes") {
                await refTBoxes.value.loadData(0, null);
                disableKeyCapture();
                showTBoxes.value = true;
            } else {
                switch (hotkey.tag.hotKeyType) {
                    case HotKeyTypes.PinTicketSmall:
                    case HotKeyTypes.PinTicketMedium:
                    case HotKeyTypes.PinTicketLarge:
                    case HotKeyTypes.PinTicketKingSize:
                    case HotKeyTypes.PinTicketCustom:
                    case HotKeyTypes.PinTicketCategory:
                        lastKeyinput = "";
                        disableKeyCapture();
                        try {
                            processingHotkey.value = true;

                            emitter.emit("server-call-start", "Generating Pin...");

                            var pincodeModel = await apiPincode.generate(hotkey.tag.globalId);
                            
                            if (refDisablePrint.value.value !== "1") {                                                                
                                var url = `pin-ticket.html?pincode=${encodeURIComponent(pincodeModel.pinCodeSecure)}`;
                                refPrintFrame.value.src = url
                                
                            }                            
                            processingHotkey.value = false;
                        } catch (err) {
                            console.log("ERROR:", err);
                            emitter.emit("show-alert", ["Error loading data", err]);
                        }
                        emitter.emit("server-call-stop");
                        enableKeyCapture();
                        break;

                    case HotKeyTypes.RangeCard:
                        lastKeyinput = "";
                        disableKeyCapture();
                        refMemberReload.value.setMember();
                        refMemberReload.value.selectData = hotkey.tag;
                        showMemberReload.value = true;
                        break;
                    case HotKeyTypes.TBox:
                        lastKeyinput = "";
                        disableKeyCapture();
                        await refTBoxes.value.loadData(hotkey.tag.duration, hotkey.tag.globalId);
                        disableKeyCapture();
                        showTBoxes.value = true;
                        break;
                    case HotKeyTypes.TurnstileEntry:
                        lastKeyinput = "";
                        disableKeyCapture();
                        try {
                            processingHotkey.value = true;

                            emitter.emit("server-call-start", "Generating Pin...");

                            var doorpinModel = await apiPincode.generateDoor(hotkey.tag.globalId);

                            var doorTicketModel = new PinTicketModel();
                            doorTicketModel.pin = doorpinModel.key;
                            doorTicketModel.header = pincodePreferences.value.ticketHeader;
                            doorTicketModel.bucketSize = doorpinModel.doorName;
                            doorTicketModel.expiration = new Date(doorpinModel.expiration).toSiteDateStr();
                            if (refDisablePrint.value.value !== "1") refPrintFrame.value.src = Helper.printDoorPinTicketUrl(doorTicketModel);
                            refHiddenValue.value.value = JSON.stringify(doorTicketModel);
                            processingHotkey.value = false;
                        } catch (err) {
                            console.log("ERROR:", err);
                            emitter.emit("show-alert", ["Error loading data", err]);
                        }
                        emitter.emit("server-call-stop");
                        enableKeyCapture();
                        break;
                    case HotKeyTypes.SaleItem:
                        var messageBoxModel = new MessageBoxModel();
                        messageBoxModel.caption = "Purchased";
                        messageBoxModel.message.push("");
                        messageBoxModel.message.push(`${hotkey.tag.keyCaption} for ${hotkey.tag.priceDisplay}`);
                        messageBoxModel.message.push("");
                        messageBoxModel.btnYes = false;
                        messageBoxModel.btnNoText = "Close";
                        emitter.emit("show-message-box", messageBoxModel);
                        await apiLogging.saleItem(hotkey.tag.globalId);
                        break;
                    case HotKeyTypes.GiftCard:
                        var giftCardNumber = prompt("Swipe Gift Card");
                        if (giftCardNumber !== null && giftCardNumber.trim().length > 0) {
                            try {
                                emitter.emit("server-call-start", "Loading gift card...");
                                var newBalance = await apiGiftCard.purchase(giftCardNumber, hotkey.tag.globalId);
                                var messageBoxModel2 = new MessageBoxModel();
                                messageBoxModel2.caption = "Gift Card";
                                messageBoxModel2.message.push("Balance on gift card " + Utilities.toCurrency(newBalance));
                                messageBoxModel2.btnYes = false;
                                messageBoxModel2.btnNoText = "Close";
                                emitter.emit("show-message-box", messageBoxModel2);
                            } catch (err) {
                                console.log("ERROR:", err);
                                emitter.emit("show-alert", ["Error loading gift card", err]);
                            }
                            emitter.emit("server-call-stop");
                            return;
                        }
                        break;
                }
            }
        };

        const pinLookup = async () => {
            var pincode = prompt("Enter Pin Code");
            if (pincode !== null && pincode.trim().length > 0) {
                try {
                    emitter.emit("server-call-start", "Looking up...");
                    var pincodeModel = await apiPincode.inquire(pincode.replace("[PIN]", ""));

                    if (pincodeModel !== null) emitter.emit("lookup-pin-ticket", pincodeModel);
                    else emitter.emit("show-alert", ["Pin Look Up", { title: "Pin code not found" }]);
                } catch (err) {
                    console.log("ERROR:", err);
                    emitter.emit("show-alert", ["Error looking up", err]);
                }
                emitter.emit("server-call-stop");
            }
        };

        const getMemberByCardId = async (memberCardId) => {
            try {
                emitter.emit("server-call-start", "Getting member...");
                var customerModel = await apiMember.getByMemberCardAll(memberCardId);
                if (customerModel !== null) {
                    disableKeyCapture();
                    refMemberDisplay.value.setMember(customerModel);
                    showMemberDisplay.value = true;
                    return;
                }

                var giftCardModel = await apiGiftCard.get(memberCardId);
                if (giftCardModel !== null) {
                    var messageBoxModel = new MessageBoxModel();
                    messageBoxModel.caption = "Gift Card";
                    messageBoxModel.message.push("Balance on gift card " + Utilities.toCurrency(giftCardModel.balance));
                    messageBoxModel.btnYes = false;
                    messageBoxModel.btnNoText = "Close";
                    emitter.emit("show-message-box", messageBoxModel);
                    return;
                }

                emitter.emit("show-alert", ["No Member Found", "There is no member associated with this card."]);
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error getting member", err]);
            } finally {
                emitter.emit("server-call-stop");
            }
        };

        const getData = async () => {
            try {
                emitter.emit("server-call-start", "Loading...");
                var settings = await apiSettings.getHotkeyColors();

                var options = await apiStation.getOptions();
                enableTBox = options.availableTBox;
                enableCashLog = options.availableBillAcceptor;

                var keys = await apiHotkey.getActive();
                keys.forEach((element) => {
                    var button = ButtonModel.CreateWithHotkey(element.keyCaption, element.keyColor, ButtonTypes.Clickable, "", null, element.hotKeyCmd);
                    button.tag = element;
                    hotkeys.value.push(button);

                    switch (element.hotKeyType) {
                        case HotKeyTypes.PinTicketSmall:
                            button.title = "Click to purchase small bucket";
                            hotkeyBuckets.value.push(button);
                            break;
                        case HotKeyTypes.PinTicketMedium:
                            button.title = "Click to purchase medium bucket";
                            hotkeyBuckets.value.push(button);
                            break;
                        case HotKeyTypes.PinTicketLarge:
                            button.title = "Click to purchase large bucket";
                            hotkeyBuckets.value.push(button);
                            break;
                        case HotKeyTypes.PinTicketKingSize:
                            button.title = "Click to purchase jumbo bucket";
                            hotkeyBuckets.value.push(button);
                            break;
                        case HotKeyTypes.PinTicketCustom:
                            button.title = "Click to purchase bucket";
                            hotkeyBuckets.value.push(button);
                            break;
                        case HotKeyTypes.PinTicketCategory:
                            button.title = "Click to purchase bucket";
                            hotkeyBuckets.value.push(button);
                            break;
                        case HotKeyTypes.RangeCard:
                            button.title = "Click to reload member balance...";
                            hotkeyReloads.value.push(button);
                            break;
                        case HotKeyTypes.TBox:
                            button.title = "Click to activate Tee-Box...";
                            hotkeyTBox.value.push(button);
                            break;
                        case HotKeyTypes.SaleItem:
                            button.title = "Click to purchase item...";
                            hotkeySaleItems.value.push(button);
                            break;
                        case HotKeyTypes.TurnstileEntry:
                            button.title = "Click to purchase item...";
                            hotkeyDoors.value.push(button);
                            break;
                        case HotKeyTypes.GiftCard:
                            button.title = "Click to purchase item...";
                            hotkeySaleItems.value.push(button);
                            break;
                    }
                });

                var lookupmemberCard = ButtonModel.CreateClickColor("Lookup Card", settings.colorSystem);
                lookupmemberCard.title = "Look up card...";
                hotkeys.value.push(lookupmemberCard);
                hotkeySystem.value.push(lookupmemberCard);

                var lookupPin = ButtonModel.CreateClickColor("Lookup Pincode", settings.colorSystem);
                lookupPin.title = "Look up pincode...";
                hotkeys.value.push(lookupPin);
                hotkeySystem.value.push(lookupPin);

                var lookupMember = ButtonModel.CreateClickColor("Lookup Member", settings.colorSystem);
                lookupMember.title = "Look up member...";
                hotkeys.value.push(lookupMember);
                hotkeySystem.value.push(lookupMember);

                if (enableCashLog) {
                    var cashLog = ButtonModel.CreateClickColor("Cash Log", settings.colorSystem);
                    cashLog.title = "View cash transactions for last 7 days";
                    hotkeys.value.push(cashLog);
                    hotkeySystem.value.push(cashLog);
                }

                if (!portalUser.disableManualTbox && enableTBox) {
                    var tboxManager = ButtonModel.CreateClickColor("Tee-Boxes", settings.colorSystem);
                    tboxManager.title = "Turn on/off Tee-Boxes";
                    hotkeys.value.push(tboxManager);
                    hotkeySystem.value.push(tboxManager);
                }

                var shiftReport = ButtonModel.CreateClickColor("Cashier Daily Report", settings.colorSystem);
                shiftReport.title = "Cashier Daily report";
                hotkeys.value.push(shiftReport);
                hotkeySystem.value.push(shiftReport);

                var exitHome = ButtonModel.CreateRouteColor("Exit", "Home", settings.colorSystem);
                exitHome.title = "Back to Main Page";
                hotkeys.value.push(exitHome);
                hotkeySystem.value.push(exitHome);

                var signOut = ButtonModel.CreateClickColor("Sign Out", settings.colorSystem);
                signOut.title = "Sign out";
                hotkeys.value.push(signOut);
                hotkeySystem.value.push(signOut);

                pincodePreferences.value = await apiSettings.getPinCode();

                await getStatusStatus();
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error loading data", err]);
            }
            emitter.emit("server-call-stop");
        };

        const enableStation = async (stationId, status) => {            
            await apiStation.setStationOperationStatus(stationId, status)
        }

        return {
            hotkeys,
            procesHotkey,
            textColor,
            captionFontSize,
            commandFontSize,
            refCashLog,
            refTBoxes,
            showCashLog,
            showTBoxes,
            hotkeyBuckets,
            hotkeyReloads,
            hotkeyTBox,
            hotkeySaleItems,
            hotkeyDoors,
            hotkeySystem,
            enableKeyCapture,
            stationStatus,
            showEndOfShift,
            refEndOfShift,
            onReportClose,
            refPrintFrame,
            refHiddenValue,
            refDisablePrint,

            refMemberLookup,
            showMemberLookup,

            refMemberDisplay,
            showMemberDisplay,

            refMemberReload,
            showMemberReload,

            onMemberClose,
            processingHotkey,
            buttonFontFamily,
            
            enableStation
        };
    }
};
</script>

<style>
.cashier-top-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 100%;
    overflow-y: auto;
}

.cashier-container {
    width: 80%;
}

.hotkey-container {
    display: flex;
    align-content: flex-start;
    background-color: var(--main-background);
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}

.status-container {
    padding-top: 50px;
    font-size: 12pt;
    width: 20%;
    display: flex;
    flex-flow: column;
    background: rgb(218, 218, 218);
    /* border-left: 1px black solid; */
}

.status {
    width: 90%;
    margin: 10px;
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    text-align: left;
}

.station-name {
    width: 80%;
    margin: auto 0px
}

.station {
    width: 20%;
    display: flex;
    justify-content: flex-end;
    margin: auto;
}

.station-up {
    width: 12px;
    height: 12px;
    background: green;
    border-radius: 2px;
}

.station-down {
    width: 12px;
    height: 12px;
    background: red;
    border-radius: 2px;
}
</style>
