<template>
    <div class="backdrop" @click.self="onClose">
        <div class="report-modal">
            <div>
                <RangeSelector @on-view="onView" @on-close="onClose" />
            </div>
            <div style="display:flex; flex-direction: columns;justify-content:space-between;background-color: #599f00;">
                <div class="report-title">{{ reportTitle }}</div>
                <div style="display:flex; flex-direction: columns;justify-content:flex-end;background-color: #2196e2;" v-show="dataReport != null">
                    <div class="report-export" @click="savePdf">PDF</div>
                    <div class="report-export" @click="exportCsv">CSV</div>
                </div>
            </div>
            <div style="background: white; overflow-y: auto;height: 90%;" v-show="dataReport != null">
                <div style="display:flex; flex-direction: columns;justify-content:flex-end;background-color: #eeffee; color:black;padding: 0px; border: 1px solid black; margin-bottom: 0px;">
                    <span style="margin: 10px;margin-right: 10px;">
                        <input
                            type="checkbox"
                            id="chkRetrospectiveView"
                            v-model="retrospectiveView"
                            title="Adjust report to retroactively include any refunds or other adjustments made in the future"
                            aria-label="Adjust report to retroactively include any refunds or other adjustments made in the future"
                        />
                        <label for="chkRetrospectiveView" title="Adjust report to retroactively include any refunds or other adjustments" aria-label="Adjust report to retroactively include any refunds or other adjustments made in the future"
                            >Retrospective View</label
                        >
                    </span>
                </div>

                <table class="report-div-table" cellspacing="0">
                    <tr v-for="row in dataReport" :key="row.heading" class="report-div-table-row">
                            <td class="report-div-table-col" :style="'width:20%;' + (row.bold ? 'font-weight: bold;font-size:11pt' : '')">{{ row.heading }}&nbsp;</td>
                            <td class="report-div-table-col" style="width:40%;">{{ row.measure }}</td>
                            <td :class="'report-div-table-col' + (row.credit ? ' credit-value' : '')" :style="'width:40%;text-align:right;' + (row.bold ? 'font-weight: bold;font-size:11pt' : '')">{{ row.value }}</td>                    
                    </tr>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import RangeSelector from "@/views/components/Reports/RangeSelector.vue";
import apiReport from "@/api/apiReport";
import { storage } from "@/common/storage";
import { ref } from "@vue/reactivity";
import { getCurrentInstance, watch } from "@vue/runtime-core";
import Utilities from "@/common/utilities";
import { jsPDF } from "jspdf";
import Helper from "@/common/helper";
import PortalUserModel from "@/common/models/PortalUserModel";

export default {
    components: {
        RangeSelector
    },
    setup(props, { emit }) {
        const emitter = getCurrentInstance().appContext.app.config.globalProperties.emitter;
        const fetchingData = ref(true);

        const reportTitle = ref("Summary Report");
        const reportfilename = ref("SummaryReport");

        const dataReport = ref(null);
        const retrospectiveView = ref(true);

        var portalUser = PortalUserModel.fromToken();

        watch(retrospectiveView, () => {            
            setData(data);
        });

        const setData = () => {
            data.retrospectiveView = retrospectiveView.value;

            dataReport.value = [
                // Income from POS - Item
                {
                    //heading: "Sales from POS" + ((portalUser.enablePos) ? " - By item" : ""),
                    heading: "Sales from POS",
                    measure: "",
                    value: Utilities.toCurrency(data.manualEntry + data.pinCode + data.rangeCardReloads + data.salesItems + data.doorTurnstile + data.tBox + data.cashierRefunds + data.giftCreditPurchased),
                    raw: data.manualEntry + data.pinCode + data.rangeCardReloads + data.salesItems + data.doorTurnstile + data.tBox + data.cashierRefunds + data.giftCreditPurchased,
                    bold: false,
                    credit: false
                },
                {
                    heading: "",
                    measure: "Manual Entry",
                    value: Utilities.toCurrency(data.manualEntry),
                    raw: data.manualEntry,
                    bold: false,
                    credit: false
                },
                {
                    heading: "",
                    measure: "Pin Code",
                    value: Utilities.toCurrency(data.pinCode),
                    raw: data.pinCode,
                    bold: false,
                    credit: false
                },
                {
                    heading: "",
                    measure: "Range Card Reloads",
                    value: Utilities.toCurrency(data.rangeCardReloads),
                    raw: data.rangeCardReloads,
                    bold: false,
                    credit: false
                },
                {
                    heading: "",
                    measure: "Sales Items",
                    value: Utilities.toCurrency(data.salesItems),
                    raw: data.salesItems,
                    bold: false,
                    credit: false
                },
                {
                    heading: "",
                    measure: "Door/Turnstiles",
                    value: Utilities.toCurrency(data.doorTurnstile),
                    raw: data.doorTurnstile,
                    bold: false,
                    credit: false
                },
                {
                    heading: "",
                    measure: "Tee-Boxes",
                    value: Utilities.toCurrency(data.tBox),
                    raw: data.tBox,
                    bold: false,
                    credit: false
                },
                {
                    heading: "",
                    measure: "Gift Cards",
                    value: Utilities.toCurrency(data.giftCreditPurchased),
                    raw: data.giftCreditPurchased,
                    bold: false,
                    credit: false
                }
            ];

            if (portalUser.enablePos) {
                dataReport.value.push(
                    // Income from POS - By payment
                    {
                        heading: "Income from POS",
                        measure: "",
                        value: Utilities.toCurrency(data.incomeFromPosCash + data.incomeFromPosCreditCardDisplay + data.refundIncomeFromPos),
                        raw: data.incomeFromPosCashDisplay + data.incomeFromPosCreditCardDisplay,
                        bold: true,
                        credit: false
                    },
                    {
                        heading: "",
                        measure: "Cash",
                        value: Utilities.toCurrency(data.incomeFromPosCashDisplay),
                        raw: data.incomeFromPosCashDisplay,
                        bold: false,
                        credit: false
                    },
                    {
                        heading: "",
                        measure: "Credit Card",
                        value: Utilities.toCurrency(data.incomeFromPosCreditCardDisplay),
                        raw: data.incomeFromPosCreditCardDisplay,
                        bold: false,
                        credit: false
                    },
                    {
                        heading: "",
                        measure: "Total Refunds",
                        value: Utilities.toCurrency(data.refundIncomeFromPos),
                        raw: data.refundIncomeFromPos,
                        bold: false,
                        credit: false
                    }
                );
            }

            dataReport.value.push(
                // Income from Dispenser
                {
                    heading: "Income from Dispenser",
                    measure: "",
                    value: Utilities.toCurrency(data.bucketPurchaseCash + data.bucketPurchaseCreditCardsDisplay + data.bucketPurchaseRangeCardReloadsDisplay + data.refundIncomeFromDispenser),
                    raw: data.bucketPurchaseCash + data.bucketPurchaseCreditCardsDisplay + data.bucketPurchaseRangeCardReloadsDisplay + data.refundIncomeFromDispenser,
                    bold: true,
                    credit: false
                },

                {
                    heading: "",
                    measure: "Member Balance Reloads",
                    value: Utilities.toCurrency(data.bucketPurchaseRangeCardReloadsDisplay),
                    raw: data.bucketPurchaseRangeCardReloadsDisplay,
                    bold: false,
                    credit: false
                },
                {
                    heading: "",
                    measure: "Bucket Purchase - Credit Card",
                    value: Utilities.toCurrency(data.bucketPurchaseCreditCardsDisplay),
                    raw: data.bucketPurchaseCreditCardsDisplay,
                    bold: false,
                    credit: false
                },
                {
                    heading: "",
                    measure: "Bucket Purchase - Cash",
                    value: Utilities.toCurrency(data.bucketPurchaseCash),
                    raw: data.bucketPurchaseCash,
                    bold: false,
                    credit: false
                },
                {
                    heading: "",
                    measure: "Total Refunds",
                    value: Utilities.toCurrency(data.refundIncomeFromDispenser),
                    raw: data.refundIncomeFromDispenser,
                    bold: false,
                    credit: false
                },

                // Income from Mobile App
                {
                    heading: "Income from Mobile App",
                    measure: "",
                    value: Utilities.toCurrency(data.incomeFromMobileReloadsDisplay + data.incomeFromMobileTBoxDisplay + data.incomeFromMobilePincodeDisplay + data.refundIncomeFromVendor),
                    raw: data.incomeFromMobileReloadsDisplay + data.incomeFromMobileTBoxDisplay + data.incomeFromMobilePincodeDisplay + data.refundIncomeFromVendor,
                    bold: true,
                    credit: false
                },
                {
                    heading: "",
                    measure: "Member Balance Reloads",
                    value: Utilities.toCurrency(data.incomeFromMobileReloadsDisplay),
                    raw: data.incomeFromMobileReloadsDisplay,
                    bold: true,
                    credit: false
                },

                {
                    heading: "",
                    measure: "Tee-Box Purchase",
                    value: Utilities.toCurrency(data.incomeFromMobileTBoxDisplay),
                    raw: data.incomeFromMobileTBoxDisplay,
                    bold: true,
                    credit: false
                },
                {
                    heading: "",
                    measure: "Pincode Purchase",
                    value: Utilities.toCurrency(data.incomeFromMobilePincodeDisplay),
                    raw: data.incomeFromMobilePincodeDisplay,
                    bold: true,
                    credit: false
                },
                {
                    heading: "",
                    measure: "Total Refunds",
                    value: Utilities.toCurrency(data.refundIncomeFromVendor),
                    raw: data.refundIncomeFromVendor,
                    bold: false,
                    credit: false
                }
            );

            // if (data.incomeFromWebPurchaseBucket !== 0) {
            //     dataReport.value.push(
            //         // Income from Web
            //         {
            //             heading: "Income from Web",
            //             measure: "",
            //             value: Utilities.toCurrency(data.incomeFromWebPurchaseBucket),
            //             raw: data.incomeFromWebPurchaseBucket,
            //             bold: true,
            //             credit: false
            //         },
            //         {
            //             heading: "",
            //             measure: "Bucket Purchase",
            //             value: Utilities.toCurrency(data.incomeFromWebPurchaseBucket),
            //             raw: data.incomeFromWebPurchaseBucket,
            //             bold: false,
            //             credit: false
            //         }
            //     );
            // }

            dataReport.value.push(
                // Range Card
                {
                    heading: "",
                    measure: "",
                    raw: undefined,
                    bold: false,
                    credit: false
                },
                {
                    heading: "Range Card",
                    measure: "",
                    raw: undefined,
                    bold: false,
                    credit: false
                },
                {
                    heading: "",
                    measure: "Credits Purchased",
                    value: Utilities.toCurrency(data.creditPurchased),
                    raw: data.creditPurchased,
                    bold: false,
                    credit: true
                },
                {
                    heading: "",
                    measure: "Credits Used",
                    value: Utilities.toCurrency(data.creditUsed),
                    raw: data.creditUsed,
                    bold: false,
                    credit: true
                },
                {
                    heading: "",
                    measure: "Current Total Balance",
                    value: Utilities.toCurrency(data.currentTotalBalance),
                    raw: data.currentTotalBalance,
                    bold: false,
                    credit: true
                },
                {
                    heading: "",
                    measure: "Current Total Customers with Balance",
                    value: Utilities.toNumeric(data.currentTotalCustomersWithBalance),
                    raw: data.currentTotalCustomersWithBalance,
                    bold: false,
                    credit: false
                },

                // Gift Card
                {
                    heading: "",
                    measure: "",
                    raw: undefined,
                    bold: false,
                    credit: false
                },
                {
                    heading: "Gift Card",
                    measure: "",
                    raw: undefined,
                    bold: false,
                    credit: false
                },
                {
                    heading: "",
                    measure: "Credits Purchased",
                    value: Utilities.toCurrency(data.giftCreditPurchased),
                    raw: data.giftCreditPurchased,
                    bold: false,
                    credit: true
                },
                {
                    heading: "",
                    measure: "Credits Used",
                    value: Utilities.toCurrency(data.giftCreditUsed),
                    raw: data.giftCreditUsed,
                    bold: false,
                    credit: true
                },
                // {
                //     heading: "",
                //     measure: "Current Total Balance",
                //     value: Utilities.toCurrency(data.giftCurrentTotalBalance),
                //     raw: data.currentTotalBalance,
                //     bold: false,
                //     credit: true
                // },

                // Other
                {
                    heading: "",
                    measure: "",
                    raw: undefined,
                    bold: false,
                    credit: false
                },
                {
                    heading: "Total Balls Dispensed",
                    measure: "",
                    value: Utilities.toNumeric(data.totalBallsDispensed),
                    raw: data.totalBallsDispensed,
                    bold: false,
                    credit: false
                },
                {
                    heading: "Total Minutes Used",
                    measure: "",
                    value: Utilities.toNumeric(data.totalMinutesUsed),
                    raw: data.totalMinutesUsed,
                    bold: false,
                    credit: false
                },
                {
                    heading: "Outstanding Pin Code",
                    measure: "",
                    value: data.outstandingPinCode,
                    raw: data.outstandingPinCode,
                    bold: false,
                    credit: false
                },
                {
                    heading: "Outstanding Door Pin Code",
                    measure: "",
                    value: data.outstandingDoorPinCode,
                    raw: data.outstandingDoorPinCode,
                    bold: false,
                    credit: false
                }
            );

            // If Retrospective View then don't show Total Refunds
            if (retrospectiveView.value) {
                dataReport.value = dataReport.value.filter(x=> x.measure != "Total Refunds")
            }
        };

        const onClose = () => {
            dataReport.value = null;
            emit("on-close");
        };

        var data;
        const onView = async () => {
            try {
                fetchingData.value = true;
                emitter.emit("server-call-start", "Loading...");

                var dataX = await apiReport.summary(storage.getReportDateStart(), storage.getReportDateEnd(), 0, false);
                data = dataX[0];
                setData(data[0]);
            } catch (err) {
                console.log("ERROR:", err);
                emitter.emit("show-alert", ["Error loading data", err]);
            }
            emitter.emit("server-call-stop");
            fetchingData.value = false;
        };

        const exportCsv = () => {
            var csvData = [];
            dataReport.value.forEach((item) => {
                var row = {
                    Section: item.heading.toExcelSafe(),
                    Measure: item.measure.toExcelSafe(),
                    Value: item.raw
                };
                csvData.push(row);
            });
            Helper.exportCsv(csvData, reportfilename.value);
        };

        const savePdf = () => {
            const doc = new jsPDF();

            // Logo
            var img = new Image();
            img.src = "/images/icons/logo-sm.png";
            doc.addImage(img, "png", 10, 10, 60, 17);

            // Title
            doc.text(reportTitle.value, 10, 35);

            // Date Range
            doc.setFontSize(10);
            doc.text(
                `${storage
                    .getReportDateStart()
                    .toDateWithFormat("YYYY-MM-DD")
                    .toFullDateString()} To ${storage
                    .getReportDateEnd()
                    .toDateWithFormat("YYYY-MM-DD")
                    .toFullDateString()}`,
                200,
                35,
                "right"
            );

            // Header Separater
            doc.line(10, 40, 200, 40);

            // Generated Date Footer
            var page = 1;
            doc.setFontSize(8);
            doc.text("Generated " + new Date().toFullDateString() + "" + new Date().toLocaleTimeString(), 200, 290, "right");
            doc.text("Page " + page.toString(), 10, 290);

            // Body
            doc.setFontSize(10);
            var yPos = 50;
            var lineHeight = 7;

            var xSection = 12;
            var xField = 85;
            var xValue = 200;

            // Sales from POS - by item
            doc.setFontSize(11).setFont(undefined, "normal");
            //doc.text("Income from POS" + ((portalUser.enablePos) ? " - By item" : ""), xSection, yPos);
            doc.text("Sales from POS", xSection, yPos);
            doc.text(Utilities.toCurrency(data.manualEntry + data.pinCode + data.rangeCardReloads + data.salesItems + data.doorTurnstile + data.tBox + data.cashierRefunds + data.giftCreditPurchased), xValue, yPos, "right");
            doc.setFontSize(10).setFont(undefined, "normal");

            yPos += lineHeight;
            doc.text("Manual Entry", xField, yPos);
            doc.text(Utilities.toCurrency(data.manualEntry), xValue, yPos, "right");

            yPos += lineHeight;
            doc.text("Pin Code", xField, yPos);
            doc.text(Utilities.toCurrency(data.pinCode), xValue, yPos, "right");

            yPos += lineHeight;
            doc.text("Sales Items", xField, yPos);
            doc.text(Utilities.toCurrency(data.salesItems), xValue, yPos, "right");

            yPos += lineHeight;
            doc.text("Door/Turnstile", xField, yPos);
            doc.text(Utilities.toCurrency(data.doorTurnstile), xValue, yPos, "right");

            yPos += lineHeight;
            doc.text("Tee-Boxes", xField, yPos);
            doc.text(Utilities.toCurrency(data.tBox), xValue, yPos, "right");

            yPos += lineHeight;
            doc.text("Gift Cards", xField, yPos);
            doc.text(Utilities.toCurrency(data.giftCreditPurchased), xValue, yPos, "right");

            if (portalUser.enablePos) {
                // Income from POS - by payment
                yPos += lineHeight;
                doc.setFontSize(11).setFont(undefined, "bold");
                doc.text("Income from POS", xSection, yPos);
                doc.text(Utilities.toCurrency(data.incomeFromPosCashDisplay + data.incomeFromPosCreditCardDisplay + data.refundIncomeFromPos), xValue, yPos, "right");
                doc.setFontSize(10).setFont(undefined, "normal");

                yPos += lineHeight;
                doc.text("Cash", xField, yPos);
                doc.text(Utilities.toCurrency(data.incomeFromPosCashDisplay), xValue, yPos, "right");

                yPos += lineHeight;
                doc.text("Credit Card", xField, yPos);
                doc.text(Utilities.toCurrency(data.incomeFromPosCreditCardDisplay), xValue, yPos, "right");

                if (retrospectiveView.value) {
                    yPos += lineHeight;
                    doc.text("Total Refunds", xField, yPos);
                    doc.text(Utilities.toCurrency(data.refundIncomeFromPos), xValue, yPos, "right");
                }
            }

            // Income from Dispenser
            yPos += lineHeight;
            doc.setFontSize(11).setFont(undefined, "bold");
            doc.text("Income from Dispenser", xSection, yPos);
            doc.text(Utilities.toCurrency(data.bucketPurchaseCash + data.bucketPurchaseCreditCardsDisplay + data.bucketPurchaseRangeCardReloadsDisplay + data.refundIncomeFromDispenser), xValue, yPos, "right");
            doc.setFontSize(10).setFont(undefined, "normal");

            yPos += lineHeight;
            doc.text("Member Balance Reloads", xField, yPos);
            doc.text(Utilities.toCurrency(data.bucketPurchaseRangeCardReloadsDisplay), xValue, yPos, "right");

            yPos += lineHeight;
            doc.text("Bucket Purchase - Credit Card", xField, yPos);
            doc.text(Utilities.toCurrency(data.bucketPurchaseCreditCardsDisplay), xValue, yPos, "right");

            yPos += lineHeight;
            doc.text("Bucket Purchase - Cash", xField, yPos);
            doc.text(Utilities.toCurrency(data.bucketPurchaseCash), xValue, yPos, "right");

            if (retrospectiveView.value) {
                yPos += lineHeight;
                doc.text("Total Refunds", xField, yPos);
                doc.text(Utilities.toCurrency(data.refundIncomeFromDispenser), xValue, yPos, "right");
            }

            // Income from Mobile App
            yPos += lineHeight;
            doc.setFontSize(11).setFont(undefined, "bold");
            doc.text("Income from Mobile App", xSection, yPos);
            doc.text(Utilities.toCurrency(data.incomeFromMobileReloadsDisplay + data.incomeFromMobileTBoxDisplay + data.incomeFromMobilePincodeDisplay + data.refundIncomeFromVendor), xValue, yPos, "right");
            doc.setFontSize(10).setFont(undefined, "normal");

            yPos += lineHeight;
            doc.text("Member Balance Reloads", xField, yPos);
            doc.text(Utilities.toCurrency(data.incomeFromMobileReloadsDisplay), xValue, yPos, "right");

            yPos += lineHeight;
            doc.text("Tee-Box Purchase", xField, yPos);
            doc.text(Utilities.toCurrency(data.incomeFromMobileTBoxDisplay), xValue, yPos, "right");

            yPos += lineHeight;
            doc.text("Pin Code Purchase", xField, yPos);
            doc.text(Utilities.toCurrency(data.incomeFromMobilePincodeDisplay), xValue, yPos, "right");

            if (retrospectiveView.value) {
                yPos += lineHeight;
                doc.text("Total Refunds", xField, yPos);
                doc.text(Utilities.toCurrency(data.refundIncomeFromVendor), xValue, yPos, "right");
            }

            // if (data.incomeFromWebPurchaseBucket !== 0) {
            //     // Income from Web
            //     yPos += lineHeight;
            //     doc.setFontSize(11).setFont(undefined, "bold");
            //     doc.text("Income from Web", xSection, yPos);
            //     doc.text(Utilities.toCurrency(data.incomeFromWebPurchaseBucket), xValue, yPos, "right");
            //     doc.setFontSize(10).setFont(undefined, "normal");

            //     yPos += lineHeight;
            //     doc.text("Bucket Purchase", xField, yPos);
            //     doc.text(Utilities.toCurrency(data.incomeFromWebPurchaseBucket), xValue, yPos, "right");
            // }

            // Range Card
            yPos += lineHeight + lineHeight;
            doc.text("Range Card", xSection, yPos);

            yPos += lineHeight;
            doc.text("Credits Purchased", xField, yPos);
            doc.text(Utilities.toCurrency(data.creditPurchased), xValue, yPos, "right");

            yPos += lineHeight;
            doc.text("Credits Used", xField, yPos);
            doc.text(Utilities.toCurrency(data.creditUsed), xValue, yPos, "right");

            yPos += lineHeight;
            doc.text("Current Total Balance", xField, yPos);
            doc.text(Utilities.toCurrency(data.currentTotalBalance), xValue, yPos, "right");

            yPos += lineHeight;
            doc.text("Current Total Customers with Balance", xField, yPos);
            doc.text(Utilities.toNumeric(data.currentTotalCustomersWithBalance), xValue, yPos, "right");

            // Gift Card
            yPos += lineHeight + lineHeight;
            doc.text("Gift Card", xSection, yPos);

            yPos += lineHeight;
            doc.text("Credits Purchased", xField, yPos);
            doc.text(Utilities.toCurrency(data.giftCreditPurchased), xValue, yPos, "right");

            yPos += lineHeight;
            doc.text("Credits Used", xField, yPos);
            doc.text(Utilities.toCurrency(data.giftCreditUsed), xValue, yPos, "right");

            // yPos += lineHeight;
            // doc.text("Current Total Balance", xField, yPos);
            // doc.text(Utilities.toCurrency(data.giftCurrentTotalBalance), xValue, yPos, "right");

            // Others
            yPos += lineHeight + lineHeight;
            doc.text("Total Balls Dispensed", xSection, yPos);
            doc.text(Utilities.toNumeric(data.totalBallsDispensed), xValue, yPos, "right");

            yPos += lineHeight;
            doc.text("Total Minutes Used", xSection, yPos);
            doc.text(Utilities.toNumeric(data.totalMinutesUsed), xValue, yPos, "right");

            yPos += lineHeight;
            doc.text("Outstanding Pin Codes", xSection, yPos);
            doc.text(data.outstandingPinCode.toString(), xValue, yPos, "right");

            yPos += lineHeight;
            doc.text("Outstanding Door Pin Codes", xSection, yPos);
            doc.text(data.outstandingDoorPinCode.toString(), xValue, yPos, "right");

            doc.save(reportfilename.value + ".pdf");
        };

        return {
            reportTitle,
            dataReport,
            onClose,
            onView,

            savePdf,
            exportCsv,

            portalUser,
            retrospectiveView
        };
    }
};
</script>

<style scoped>
.report-modal {
    width: 90%;
    height: 70%;
    margin: 100px auto;
    background: white;
}
</style>
